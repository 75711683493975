import React from "react";
import Layout from "../components/Layout/layout";
import icon from "../images/_404.png";
import { isBrowser } from "../utils/general";
const style = {
  container: {
    height: "90vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    textAlign: "center",
  },
  btn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    textAlign: "center",
    fontWeight: "bold",
    padding: 6,
    fontSize: 22,
  },
};

const goTohome = () => {
  if (isBrowser()) {
    window.location.href = "/";
  }
};

const NotFoundPage = () => (
  <Layout>
    <div style={style.container}>
      <div style={style.content}>
        <div>
          <img src={icon} />
          <div style={style.text}>Page Not Found</div>
        </div>
        <div style={style.btn}>
          <div
            className="ts-form-next-link"
            style={{ width: 300, padding: 10, marginTop: 10 }}
            onClick={goTohome}
          >
            BACK TO HOME
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
